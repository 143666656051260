import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/generic/routes.enum';
import { Language } from '@core/enums/generic/language.enum';
import { environment } from '@environments/environment';
import { SeoService } from '@core/services/seo.service';
import { ConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-redirect',
  template: '',
  standalone: true,
})
export class RedirectComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private translateService: TranslateService,
    private configService: ConfigService,
    private router: Router,
    private seoService: SeoService,
  ) {
  }

  ngOnInit(): void {
    this.seoMetaTags(this.translateService.currentLang as Language);

    this.router.navigate(['/', this.translateService.currentLang, AppRoutes.home]);
  }

  seoMetaTags(language: Language): void {
    this.configService.config$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((item) => {
      this.seoService.updateTitle(item.metaTags[language]?.home?.title);

      this.seoService.updateGraphMeta({
        title: item.metaTags[language]?.home?.title,
        description: item.metaTags[language]?.home?.text,
        image: `${environment.landingUrl}/assets/images/home/home.png`,
        language,
      });

      this.seoService.updateBasicMeta({
        language,
        title: item.metaTags[language]?.home?.title,
        description: item.metaTags[language]?.home?.text,
      });
    })
  }
}
