import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MarketService } from '@core/services/market.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CustomHeadersInterceptor implements HttpInterceptor {
  constructor(
    private translateService: TranslateService,
    private marketService: MarketService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const whitelist = ['assets/i18n', 'market.json']

    if (!whitelist.some(item => !!request.url.includes(item))) {
      let headers: Record<string, string> = {
        'Device-type': 'web',
        'Accept-Language': this.translateService.currentLang || this.translateService.defaultLang,
        'X-App-Stilio': 'landing'
      }

      if (this.marketService.currentMarket) {
        headers = { ...headers, 'X-Market': this?.marketService?.currentMarket };
      }

      if (isPlatformBrowser(this.platformId)) {
        headers = { ...headers, 'User-Agent': window?.navigator?.userAgent };
      }

      request = request.clone({
        setHeaders: headers,
      });
    }

    return next.handle(request);
  }
}
