import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxValidatorService } from '@angular-magic/ngx-validator';
import { TranslateService } from '@ngx-translate/core';
import { ApiValidationResult } from '@model/helpers/api-result.model';
import { NgxToastService } from '@angular-magic/ngx-toast';

@Injectable({
  providedIn: 'root',
})
export class CodeInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly ngxValidatorService: NgxValidatorService,
    private readonly ngxToastService: NgxToastService,
    private readonly translateService: TranslateService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Do something on success execution
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 400:
              case 405:
              case 409:
              case 429:
                this.parseValidations(err);
                break;
              case 401:
                this.parseValidations(err);
                break;
              default:
                break;
            }
          }
        },
      ),
    );
  }

  private parseValidations(response: HttpErrorResponse): void {
    const error = response.error as ApiValidationResult;
    const keys = Object.keys(error.messages || []).filter(key => key !== 'non_field');
    const nonField = Object.keys(error.messages || []).find(key => key === 'non_field');

    if (keys && keys.length) {
      const fieldErrors = keys.reduce((acc, key) =>
        ({ ...acc, [key]: error.messages[key] }), {});

      this.ngxValidatorService.backendValidation.next(fieldErrors);
    }

    if (nonField) {
      const message = error.messages.non_field.join(' | ');
      const title = this.translateService.instant('general.failure');

      this.ngxToastService.error({ title, messages: [message] });
    }
  }
}
