import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectComponent } from './features/redirect/redirect.component';
import { ShellComponent } from './features/shell/shell.component';
import { configGuard } from '@core/guards/config.guard';

export const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    canActivate: [configGuard],
    children: [
      {
        path: '',
        component: RedirectComponent,
      },
      {
        path: 'rd',
        loadComponent: () => import('./features/source-target-redirect/source-target-redirect.component').then(c => c.SourceTargetRedirectComponent),
      },
      {
        path: 'not-found',
        loadComponent: () => import('./features/not-found/not-found.component').then(c => c.NotFoundComponent),
      },
      {
        path: 'download-stilio-business',
        loadComponent: () => import('./features/download-app/download-app.component').then(c => c.DownloadAppComponent),
        data: {
          appType: 'business'
        }
      },
      {
        path: 'download-stilio',
        loadComponent: () => import('./features/download-app/download-app.component').then(c => c.DownloadAppComponent),
        data: {
          appType: 'customer'
        }
      },
      {
        path: 'coming',
        loadComponent: () => import('./features/coming/coming.component').then(c => c.ComingComponent),
      },
      {
        path: 'old-page-browser',
        loadComponent: () => import('./features/old-page-browser/old-page-browser.component').then(c => c.OldPageBrowserComponent),
      },
      {
        path: ':lang/create-account',
        loadChildren: () => import('./features/create-account/create-account.routes').then(c => c.CREATE_ACCOUNT_ROUTES),
      },
      {
        path: ':lang',
        loadChildren: () => import('./features/landing/landing.module').then(s => s.LandingModule),
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];
