import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class BrowserDetectionService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private platform: Platform
  ) {
  }

  isOldSafari(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (this.platform.SAFARI || this.platform.IOS) {
        const userAgent = navigator.userAgent;
        const safariMatch = userAgent.match(/Version\/(\d+)/);

        if (safariMatch && safariMatch[1]) {
          const safariVersion = parseInt(safariMatch[1], 10);
          return safariVersion < 13;
        }
      }
    }

    return false;
  }
}
